import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    informationData: {},  // 表头信息
  },
  getters: {
  },
  mutations: {
    change_informationData(state,value){
        state.informationData = value
    }
  },
  actions: {
  },
  modules: {
  }
})
