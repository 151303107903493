<template>
  <div class="home">
      <div class="homeBox">
          <div class="title">AI一健生成表格</div>
          <div class="text">输入你的内容信息，让AI为你一键生成表格</div>
          <div class="flex imgBox">
              <div>
                 <div class="img1 topH">
                    <img src="../assets/img/img (7).png" alt="">
                 </div>
                 <div class="flex">
                    <div>
                        <div class="img2 topH">
                            <img src="../assets/img/img (2).png" alt="">
                        </div>
                        <div class="img3 topH">
                            <img src="../assets/img/img (3).png" alt="">
                        </div>
                        <div class="img4 topH">
                            <img src="../assets/img/img (4).png" alt="">
                        </div>
                        
                    </div>
                    <div class="img5">
                        <img src="../assets/img/img (5).png" alt="">
                    </div>
                 </div>
              </div>
              <div class="tableImg"></div>
          </div>
          <div class="btn" @click="$router.push('/initialization')">
            立 即 生 成
          </div>
      </div>
  </div>
</template>
 
<script>

 
export default {
  data() {
    return {
      message: '',
      responses: [],
    };
  },

};
</script>

<style scoped lang="scss">
 .home{
   background-color: #f5f5f5;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   background-image: url("../assets/img/img (9).png") ;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   position: relative;
 }

 
.homeBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imgBox{
    margin-top: 80px;
}
 
 .title{
    height: 84px;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    line-height: 84px;
    letter-spacing: 2px;
    text-align: center;
    font-style: normal;
    }
    .text{
        height: 42px;
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: center;
        font-style: normal;
    }
 .btn{
    width: 311px;
    height: 73px;
    background: linear-gradient( 142deg, #D4F59D 0%, #79E19A 100%);
    border-radius: 37px;
    border: 4px solid #FFFFFF;
    line-height: 73px;
    font-size: 30px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin:10px auto;

 }

 .tableImg{
    width: 422px;
    height: 279px;
    background-image: url("../assets/img/img (6).png") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 55px
 }
 .img1{
    width: 469px;
    height: 79px;
    margin-top: 18px;
    img{
        width: 100%;
        height: 100%;
    }
 }

  .img2{
     width: 303px;
     height: 121px;
    img{
        width: 100%;
        height: 100%;
    }
 }


 .img3{
    width: 353px;
    height: 61px;
    img{
        width: 100%;
        height: 100%;
    }
 }

 .img4{
    width: 360px;
    height: 61px;
    img{
        width: 100%;
        height: 100%;
    }
 }
  .img5{
    width: 216px;
    height: 164px;
    img{
        width: 100%;
        height: 100%;
    }
 }
 .topH{
     margin-top: 18px;
 }
</style>