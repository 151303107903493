<template>
  <div id="app">
    <HearderView/>
    <div class="container">
        <router-view/>
    </div>

  </div>
</template>
<script>
import HearderView from './views/HearderView.vue'

export default {
    components:{
        HearderView
    }
    
}
</script>


<style lang="scss">
#app,body,html {
  font-family: 微软雅黑, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
  font-size: 14px;
  height: 100%;
  margin:0;
  padding:0;
  box-sizing: border-box;
  overflow: hidden;
}
 .container{
       height: calc(100% - 50px);
      
 }

</style>
