<template>
  <div class='HearderView'>
    <div class="logo" @click="$router.push('/home')">logo</div>
    <div class="flex rightBox">
        <div class="text">开放平台</div>
        <div class="text" @click="()=>$router.push('/equity')">价格权益</div>
        <div class="vip flex">
            <div class="vipImg"><img src="../assets/img/img (1).png" alt=""></div>
            <div>成为VIP</div>
            
        </div>
        <div class="auth" @click="()=>$router.push('/works')"><img src="../assets/img/img (8).png" alt=""></div>
    </div>
  </div>
</template>
 
<script>

 
export default {
  name: "HearderView",
};
</script>

<style scoped lang="scss">
.HearderView{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding:0 20px
}
.logo{
    width: 79px;
    height: 50px;
    font-weight: 500;
    font-size: 36px;
    color: #333333;
    line-height: 50px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
    
}
.vip{
    width: 118px;
    height: 36px;
    line-height: 36px;
    background: #000000;
    border-radius: 18px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-right:14px;

    .vipImg{
        display: inline-block;
        padding-top: 3px;
        margin-right: 15px;
        margin-left: 20px;
        img{
            width: 16px;
            height: 16px;
        }
    }
    
}
.rightBox{
    align-items: center;
    .text{
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-right: 48px;
    }
}
.auth{
       display: flex;
       align-content: center;
        img{
            width: 38px;
            height: 38px;
            cursor: pointer;
        }
    }
    .text:hover{
        color: #000000;
        cursor: pointer;
    }
</style>
